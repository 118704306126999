import React from "react";
import iconComerAqui from "../../assets/image/icon.png";

export const HeaderProduto = ({ img, alt, nome, descricao, preco }) => {
  return (


    <div style={{display: "flex", justifyContent: 'flex-start',backgroundColor: "#fff", borderBottom: "solid 1px #ddd", padding: 10, margin: 0}}> 

    {!img ? (
        <img
          src={iconComerAqui}
          alt="Comer Aqui"
          style={{ opacity: 0.35, height: 72, objectFit: "contain" }}
        />
      ) : (
        <img
          height={100}
          src={img}
          alt={alt}
          style={{padding: 10, borderRadius: 5, backgroundColor: "#fff" }}
        //   onError={this.onError.bind(this)}
        />
      )}

      <div style={{marginLeft: 10}}>

        <h4 style={{color: "#444"}}> {nome}</h4>
        <p> {descricao}</p>
        <p> {preco}</p>

      </div>


    </div>


  );
};
