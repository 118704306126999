import React, { Component } from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import Currency from "../../utils/currency";

import "./styles.css";

import Header from "../../components/Header";

import { Creators as CartCreator } from "../../store/ducks/cart";

import { FaArrowLeft, FaPlus, FaMinus, FaCamera } from "react-icons/fa";

import { toast } from "react-toastify";

import ItemProduct from "../../components/ItemProduct/index";

import iconComerAqui from "../../assets/image/icon.png";

import ImageModal from "../../components/ImageModal/index";

let idSeq = 1;
const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

class ScreenSelectProducts extends Component {
  constructor(props) {
    super(props);

    // console.log("inVerificaimpressora", this.props.product.product.grupo.inVerificaimpressora);

    window.scrollTo(0, 0);

    let { attrs } = props.product.product;

    let { products } = props.product;

    let items = [];

    // valida pelo idGrupo
    for (let product of products) {
      if (
        product.idProduto !== attrs.idProduto &&
        product.idGrupo == attrs.idGrupo
      ) {
        if (
          product.idGrade == attrs.idGrade &&
          product.idProdutoGrade == attrs.idProduto
        ) {
          product.quantity = 0;
          product.vlTotal = 0;
          items.push(product);
        }
      }
    }

    this.state = {
      product: attrs,
      items,
      itemsCart: [],
      errored: [],
      modalVisible: false,
      urlImgModal: "",
    };
  }

  addItem = (item) => {
    return {
      id: `PRODUTO_SELECIONADO_${Math.floor(Math.random() * 256)}${idSeq++}${
        item.idProduto
      }`,
      ordered: this.props.cart.id,
      product: {
        id: item.idProduto,
        name: item.dsProduto,
        quantity: item.quantity,
        value: item.vlTotal,
        total: item.vlTotal,
        note: [],
      },
      status: 1,
      remove: this.props.product.remove,
      add: this.props.product.add,
      observation: "",
      inVerificaimpressora:
        this.props.product.product.grupo.inVerificaimpressora,
    };
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let trava = false;

    if (this.props.cart.id == null) {
      const numberOrder = localStorage.getItem("@comer-aqui/numberOrder");
      const typeOrder = localStorage.getItem("@comer-aqui/typeOrder");
      this.props.newCart(typeOrder, numberOrder);
    }

    const { itemsCart } = this.state;

    if (itemsCart.length == 0) {
      toast.error("Nenhum produto selecionado");
      return;
    }

    for (let itemCart of itemsCart) {
      const itemAdd = this.addItem(itemCart);
      let items = [];
      items.push(itemAdd);
      this.props.addItem(itemAdd);
    }

    if (this?.props?.product?.product?.attrs?.qtMinima > 0) {
      if (
        this.state.quantity < this?.props?.product?.product?.attrs?.qtMinima
      ) {
        trava = true;
        toast.error(
          `Para compra esse item a quantidade minima é de ${this?.props?.product?.product?.attrs?.qtMinima}`
        );
      }
    }

    if (!trava) {
      this.props.history.push({ pathname: "/cart" });
    }
  };

  // handleSubmit = (itemAdd) => {

  //     if (this.props.cart.id == null) {
  //         const numberOrder = localStorage.getItem('@comer-aqui/numberOrder');
  //         const typeOrder = localStorage.getItem('@comer-aqui/typeOrder');
  //         this.props.newCart(typeOrder, numberOrder);
  //     }

  //     let items = [];
  //     items.push(itemAdd);
  //     this.props.addItem(itemAdd);

  //     this.props.history.push({ pathname: '/cart' });
  // }

  async handleAddProduct(pizza) {
    let { itemsCart } = this.state;

    let quantitys = 0;

    for (let product of itemsCart) {
      quantitys = parseInt(quantitys) + parseInt(product.quantity);
    }
    let idProdutos = itemsCart.map(({ idProduto }) => idProduto);

    if (idProdutos.includes(pizza.idProduto)) {
      for (let product of itemsCart) {
        if (product.idProduto == pizza.idProduto) {
          product.vlTotal =
            parseFloat(product.vlTotal) + parseFloat(pizza.vlPreco);

          if (product.qtIncremento > 0) {
            product.quantity =
              parseInt(product.quantity) + product.qtIncremento;
          } else {
            product.quantity = parseInt(product.quantity) + 1;
          }
        }
      }
      this.setState({ itemsCart });
    } else {
      pizza.vlTotal = pizza.vlPreco;
      pizza.quantity = 1;

      if (pizza.qtIncremento > 0) {
        pizza.quantity = pizza.qtIncremento;
      }

      this.setState({ itemsCart: [...itemsCart, pizza] });
    }
  }

  async handleRemoveProduct(pizza) {
    let { itemsCart } = this.state;

    let quantitys = 0;

    for (let product of itemsCart) {
      if (product.idProduto == pizza.idProduto) {
        quantitys = parseInt(quantitys) + parseInt(product.quantity);
      }
    }

    if (parseInt(quantitys) == 1) {
      pizza.vlTotal = 0;
      pizza.quantity = 0;
      itemsCart.splice(itemsCart.indexOf(pizza), 1);
      this.setState({ itemsCart });
    } else {
      for (let product of itemsCart) {
        if (product.idProduto == pizza.idProduto) {
          product.vlTotal =
            parseFloat(product.vlTotal) - parseFloat(pizza.vlPreco);

          if (product.qtIncremento > 0) {
            if (parseInt(product.quantity) - product.qtIncremento > 0) {
              product.quantity =
                parseInt(product.quantity) - product.qtIncremento;
            } else {
              product.quantity = 0;
              pizza.vlTotal = 0;
              pizza.quantity = 0;
              itemsCart.splice(itemsCart.indexOf(pizza), 1);
              this.setState({ itemsCart });
            }
          } else {
            product.quantity = parseInt(product.quantity) - 1;
          }
        }
      }
      this.setState({ itemsCart });
    }
  }

  handleAddedOnCart = (item) => {
    let { itemsCart } = this.state;

    let idProdutos = itemsCart.map(({ idProduto }) => idProduto);

    if (idProdutos.includes(item.idProduto)) {
      return "content-item-flavors-added";
    } else {
      return "content-item-flavors";
    }
  };

  onError(item) {
    if (!this.state.errored.includes(item.idProduto)) {
      this.setState({ errored: [...this.state.errored, item.idProduto] });
    }
  }

  handleShowModal = (image) => {
    if (image !== undefined) {
      this.handleSetImageModal(image);
    }

    this.setState({ modalVisible: !this.state.modalVisible }, () => {});
  };

  handleSetImageModal = (image) => {
    this.setState({ urlImgModal: image });
  };

  render() {
    const {
      product: { product },
    } = this.props;

    const { modalVisible, urlImgModal } = this.state;

    let { items, errored } = this.state;

    return (
      <div
        style={{ display: "flex", flexDirection: "column", height: "100vh" }}
      >
        <Header />
        <ImageModal
          show={modalVisible}
          url={urlImgModal}
          onClickClose={() => this.handleShowModal()}
        />

        <div style={{ flex: 1, overflowY: "auto", margin: 0, padding: 0 }}>


          {/* Cesar - 22/07/2022 */}
          {product.attrs.inComplemento === "S" ? (
            <div className="content " style={{ padding: 0, margin: 0 }}>
              <section id="product-list">
                {items.map((product) => (
                  <div key={product.idProduto}>
                    <ItemProduct
                      grupo={this.props.product.product.grupo}
                      grupo_id={0}
                      key={product.idProduto}
                      id={product.idProduto}
                      url={product.dsImagem}
                      alt={product.dsProduto}
                      name={product.dsProduto}
                      description={product.dsIngredientes}
                      price={product.vlPreco}
                      promotion_price={product.vlPromocao}
                      type={"N"}
                      remove={product.remove}
                      add={product.add}
                      complement={product.inComplemento}
                      history={this.props.history}
                      showModal={this.handleShowModal}
                      ingredients={product.ingredientes}
                      additional={this.state.additional}
                      attrs={product}
                      all_products={this.props.product}
                    />
                  </div>
                ))}
              </section>
            </div>
          ) : (
            items.map((item) => (
              <div
                key={item.idProduto}
                style={{
                  backgroundColor: "#fff",
                  borderRadius: 10,
                  marginBottom: 10,
                  border: "none",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  flexWrap: "wrap",
                  overflow: "hidden",
                  borderBottom: "1px solid #f1f1f1",
                  borderRight: "1px solid #f1f1f1",
                  cursor: "pointer",
                  gap: 20,
                }}
              >
                <div>
                  {errored.includes(item.idProduto) || !item.dsImagem ? (
                    <img
                      src={iconComerAqui}
                      alt="Comer Aqui"
                      onClick={this.handleShowModal}
                      className="img-fluid mb-1"
                      style={{
                        cursor: "pointer",
                        opacity: 0.35,
                        width: 80,
                        objectFit: "contain",
                      }}
                    />
                  ) : (
                    <img
                      className="product-note-img"
                      onClick={this.handleShowModal}
                      src={item.dsImagem}
                      alt={item.dsProduto}
                      onError={() => this.onError(item)}
                      style={{ cursor: "pointer", width: 80 }}
                    />
                  )}
                </div>

                <div
                  style={{
                    flex: 1,
                    padding: 10,
                    wordWrap: "break-word",
                    wordBreak: "break-all",
                    // maxWidth: 200,
                  }}
                >
                  {" "}
                  <div>
                    <h5>{item.dsProduto}</h5>
                    <div>
                      <span>{item.dsIngredientes}</span>
                    </div>
                    <span style={{fontWeight: 'bold', fontSize: 18, color: "#ab0202"}} >{Currency.formatterValue.format(item.vlPreco)}</span>
                  </div>


         

                </div>

                <div style={{ padding: 10 }}>
                    <div
                      className="btn btn-block btn-sm btn-add-remove-quantity"
                      style={{
                        padding: 5,
                        width: 30,
                        height: 30,
                        backgroundColor: "#00d97e",
                        borderRadius: 100,
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => this.handleAddProduct(item)}
                    >
                      <FaPlus />
                    </div>

                    <h6
                      style={{ margin: "5px 0 5px 0 ", padding: 0 }}
                      className="add-remove-quantity"
                    >
                      {item.quantity}
                    </h6>

                    <div
                      className="btn btn-block btn-sm btn-add-remove-quantity"
                      style={{
                        padding: 5,
                        width: 30,
                        height: 30,
                        backgroundColor: "#ee4949",
                        borderRadius: 100,
                        color: "#fff",
                        cursor: "pointer",
                      }}
                      onClick={() => this.handleRemoveProduct(item)}
                    >
                      <FaMinus />
                    </div>
                  </div>

              </div>
            ))
          )}
        </div>

        <div style={{ marginTop: 150  }} />

        <div
          className={ "fixed-bottom"}
          style={{ backgroundColor: "#fff" }}
        >
          <div
            style={{
              height: 1,
              backgroundColor: "#f1f1f1",
              margin: "0 20px 0 20px",
            }}
          />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 10,
              margin: 20,
            }}
          >
            <button
              className="btn btn-block btn-danger btn-go-back"
              style={{ width: "100%" }}
              onClick={() => {
                this.props.history.goBack();
              }}
            >
              Voltar
            </button>
            <button
              className="btn btn-dark btn-block"
              onClick={this.handleSubmit}
            >
              Adicionar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  product: state.product,
  cart: state.cart,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(CartCreator, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ScreenSelectProducts);
